<script>
  import { onMount } from 'svelte';
  import { navigate } from 'svelte-routing';
  import Tab, { Label } from '@smui/tab';
  import TabBar from '@smui/tab-bar';
  import Button from '@smui/button';
  import Textfield from '@smui/textfield';
  import Select, { Option } from '@smui/select';
  import List, { Item, Graphic } from '@smui/list';
  import Checkbox from '@smui/checkbox';
  import {
    title,
    breadcrumbPaths,
    snackbar,
    snackbarMessage,
    isMasquerading,
    mobileView,
  } from '../../../../stores/core-store';
  import { getAccountId, getAccountRole } from '../../../../util/account';
  import PrimaryContent from '../../../../components/PrimaryContent.svelte';
  import SecondaryBackgroundWrapper from '../../../../components/SecondaryBackgroundWrapper.svelte';
  import TwoColumnSection from '../../../../components/TwoColumnSection.svelte';
  import ContentRow from '../../../../components/ContentRow.svelte';
  import ContentRowCenteredOnMobile from '../../../../components/ContentRowCenteredOnMobile.svelte';
  import DateAccountComponent from '../../../../components/DateAccountComponent.svelte';
  import { fetchClientApps } from '../../../../util/api/client-apps';
  import { fetchModules, findModuleByModuleId } from '../../../../util/api/modules';
  import { fetchClasses } from '../../../../util/api/classes';
  import { fetchInstructors } from '../../../../util/api/accounts';
  import FilledTextArea from '../../../../components/FilledTextArea.svelte';
  import { createSessionPlan, editSessionPlan, fetchSessionPlan, SessionStatus } from '../../../../util/api/sessions';
  import LoadingView from '../../../../components/LoadingView.svelte';
  import ClientAppLogo from '../../../../components/client-apps/ClientAppLogo.svelte';
  import SessionPermissionSelect from '../../../../components/sessions/SessionPermissionSelect.svelte';
  import SessionScenesPanel from '../../../../components/sessions/SessionScenesPanel.svelte';
  import GigXrDialog from '../../../../components/gigxr-dialog/GigXrDialog.svelte';
  import GigXrDialogContent from '../../../../components/gigxr-dialog/GigXrDialogContent.svelte';
  import GigXrDialogIcon from '../../../../components/gigxr-dialog/GigXrDialogIcon.svelte';
  import GigXrDialogActions from '../../../../components/gigxr-dialog/GigXrDialogActions.svelte';
  import GigXrDialogCancelButton from '../../../../components/gigxr-dialog/GigXrDialogCancelButton.svelte';
  import GigXrDialogButton from '../../../../components/gigxr-dialog/GigXrDialogButton.svelte';
  import DiscardChangesDialog from '../../../../components/DiscardChangesDialog.svelte';
  import { isLegacyHoloChem, isLegacyHoloHuman, isLegacyHoloPatient } from '../../../../util/unity';
  import PlatformStagePanel from '../../../../components/sessions/PlatformStagePanel.svelte';
  import PlatformV3ContentPanel from '../../../../components/sessions/PlatformV3ContentPanel.svelte';
  import { AccountRole } from '../../../../util/api/accounts';

  export let sessionId;

  title.set('View Session Plan');
  breadcrumbPaths.set([
    {
      name: 'Dashboard',
      location: '/',
    },
    {
      name: 'Session Plan List',
      location: '/sessions/plans/list',
    },
    {
      name: 'View Session Plan',
      location: `/sessions/plans/view/${sessionId}`,
    },
  ]);

  let activeTab = 'Details';
  let tabs = ['Details', 'Content'];

  // https://github.com/hperrin/svelte-material-ui/issues/67
  $: tabPromise = Promise.resolve(tabs);

  let shouldHideSaveAsSessionPlan = false;
  let clientAppVersion = '';
  let moduleNames = [];
  let modulesNames = 'None';
  let loading = true;
  let archiveDialog;
  let discardChangesDialog;
  let session;
  let lastSavedSession = {};
  let clientApps = [];
  let classes = [];
  let instructors = [];
  let modules = [];
  let moduleList = [];
  let selectedModules = [];
  let isStudent = getAccountRole() === AccountRole.STUDENT;

  let isGigXrAdmin = getAccountRole() === 'GigXrAdmin' && !$isMasquerading;
  console.log(`in session view, isGigXrAdmin: ${isGigXrAdmin}`);
  $: canEditSession = lastSavedSession.createdById === getAccountId() || isGigXrAdmin;

  function KeyPress(e) {
    var evtobj = window.event ? event : e;
    // Export session plan as json
    if (evtobj.keyCode == 69 && evtobj.ctrlKey && evtobj.shiftKey) {
      var dataStr = 'data:text/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(lastSavedSession));
      var dlAnchorElem = document.createElement('a');
      dlAnchorElem.setAttribute('href', dataStr);
      dlAnchorElem.setAttribute('download', `sessionplan-${lastSavedSession.sessionId}.json`);
      dlAnchorElem.click();
      dlAnchorElem.remove();
    }
  }

  document.onkeydown = KeyPress;

  onMount(async () => {
    [lastSavedSession, clientApps, classes, instructors, modules] = await Promise.all([
      fetchSessionPlan(sessionId),
      fetchClientApps(),
      fetchClasses(),
      fetchInstructors(),
      fetchModules(),
    ]);

    lastSavedSession.hmdJson.scenes = lastSavedSession.hmdJson.scenes ?? [];
    session = { ...lastSavedSession };
    if (session.moduleList) {
      moduleList = session.moduleList.split(',');
    }
    console.log(`in onMount, modules: ${modules}`);
    console.log(`in onMount, sessionId: ${session.sessionId}`);
    console.log(`in onMount, moduleList: ${session.moduleList}`);
    moduleList.forEach((modId) => {
      console.log(`modId is: ${modId}`);
      let md = findModuleByModuleId(modId, modules);
      if (md && md.moduleName) {
        moduleNames.push(md.moduleName);
      }
      if (moduleNames.length) {
        modulesNames = moduleNames.join(', ');
      }
      //console.log(`module is: ${md}`);
    });

    if (
      session.clientApp.clientAppName == 'HoloScenarios' &&
      (!session.clientAppVersion || !session.clientAppVersion.length)
    ) {
      clientAppVersion = '';
      shouldHideSaveAsSessionPlan = true;
    } else {
      clientAppVersion = session.clientAppVersion;
    }

    classes = classes.filter((classItem) => classItem.classStatus === 'Active');

    // cleanup
    if (clientAppVersion === null || clientAppVersion === 'null') {
      clientAppVersion = '';
    }

    loading = false;
  });

  async function saveHandler(event) {
    console.log(`in saveHandler, sessionId: ${session.sessionId}`);
    console.log(`in saveHandler, moduleId: ${session.moduleId}`);
    session.moduleList = moduleList.toString();
    console.log(`in saveHandler, moduleList: ${session.moduleId}`);
    lastSavedSession = await editSessionPlan(session);
    console.log(`in saveHandler, lastSavedSession.moduleList: ${lastSavedSession.moduleList}`);
    session = { ...lastSavedSession };
    snackbarMessage.set('Session plan saved!');
    $snackbar.open();
  }

  async function saveAsNewSessionPlanHandler(event) {
    session.sessionName = `${session.sessionName} copy`;
    lastSavedSession = await createSessionPlan(session, session.sessionId);
    session = { ...lastSavedSession };
    snackbarMessage.set('Saved as new session plan!');
    $snackbar.open();
    navigate(`/sessions/plans/view/${lastSavedSession.sessionId}`);
  }

  async function archiveSessionPlanHandler(event) {
    session.sessionStatus = SessionStatus.ARCHIVED;
    lastSavedSession = await editSessionPlan(session);
    session = { ...lastSavedSession };
    snackbarMessage.set('Session plan archived!');
    $snackbar.open();
  }

  async function unarchiveSessionPlanHandler(event) {
    session.sessionStatus = SessionStatus.ENDED;
    lastSavedSession = await editSessionPlan(session);
    session = { ...lastSavedSession };
    snackbarMessage.set('Session plan unarchived!');
    $snackbar.open();
  }

  async function createSessionFromSessionPlan(event) {
    navigate(`/sessions/create?fromPlanId=${sessionId}`);
  }
</script>

<PrimaryContent>
  {#await tabPromise then tabs}
    <TabBar {tabs} let:tab bind:active={activeTab} class="gigxr-tabs">
      <Tab {tab} minWidth>
        <Label>{tab}</Label>
      </Tab>
    </TabBar>
  {/await}
</PrimaryContent>

<SecondaryBackgroundWrapper>
  {#if loading}
    <PrimaryContent>
      <LoadingView />
    </PrimaryContent>
  {:else}
    <PrimaryContent>
      <TwoColumnSection>
        <div slot="left" hidden={activeTab !== 'Details'}>
          {#if session.gigXrBranded}
            <div class="gigxr-branded-session-plan" />
          {/if}
          <div class="logo-row">
            <div class="logo-row__logo">
              <ClientAppLogo clientAppName={session.clientApp.clientAppName} />
              <input type="hidden" name="clientAppVersion" value={session.clientAppVersion} disabled="disabled" />
              {#if clientAppVersion !== ''}
                <div class="gigxr-version-number">ver: {clientAppVersion}</div>
              {/if}
              {#if isGigXrAdmin}
                <Checkbox bind:checked={session.gigXrBranded} label="GigXR Branded Session Plan" />
              {/if}
            </div>
            <div class="logo-row__content">
              <ContentRow>
                <Textfield
                  input$id="session-plan-name-field"
                  class="gigxr-input"
                  bind:value={session.sessionName}
                  variant="filled"
                  label="Session Plan Name"
                  disabled={!canEditSession}
                />
              </ContentRow>

              <ContentRow>
                <Select
                  inputId="client-app-field"
                  class="gigxr-input"
                  bind:value={session.clientAppId}
                  variant="filled"
                  label="App"
                  enhanced
                  disabled
                >
                  <Option value="" />
                  {#each clientApps as app (app.clientAppId)}
                    <Option value={app.clientAppId} selected={app.clientAppId === session.clientAppId}>
                      {app.clientAppName}
                    </Option>
                  {/each}
                </Select>
              </ContentRow>

              {#if moduleNames.length > 0}<input type="hidden" name="moduleList" value={session.moduleList} />{/if}
              <ContentRow>
                <Textfield
                  name="no-update-modulenames"
                  class="gigxr-input"
                  value={modulesNames}
                  variant="filled"
                  label="Module"
                  enhanced
                  disabled="disabled"
                />
              </ContentRow>

              {#if isGigXrAdmin}
                <ContentRow>
                  <Select
                    inputId="app-version-field"
                    class="gigxr-input"
                    bind:value={session.clientAppVersion}
                    variant="filled"
                    label="Version"
                    enhanced
                  >
                    {#if session.clientApp.clientAppName == 'HoloChemLab'}
                      <Option value="v44" selected={false}>{session.clientApp.clientAppName} v44</Option>
                    {/if}
                    {#if session.clientApp.clientAppName == 'HoloHuman'}
                      <Option value="v44" selected={false}>{session.clientApp.clientAppName} v44</Option>
                    {/if}
                    {#if session.clientApp.clientAppName == 'HoloPatient'}
                      <Option value="v44" selected={false}>{session.clientApp.clientAppName} v44</Option>
                    {/if}
                    {#if session.clientApp.clientAppName == 'HoloScenarios'}
                      <Option value="v44" selected={false}>{session.clientApp.clientAppName} v44</Option>
                    {/if}
                  </Select>
                </ContentRow>

                <!-- The entire list must be rendered at once, otherwise clicking on a row will not trigger toggling the checkbox. -->
                <ContentRow>
                  <h4>Modules:</h4>
                </ContentRow>
                {#if !loading}
                  <ContentRow>
                    <List class="gigxr-list" checklist>
                      {#each modules as module (module.moduleId)}
                        <Item id="license-module-checkbox-{module.moduleId}">
                          <Graphic>
                            <Checkbox bind:group={moduleList} value={module.moduleId} />
                          </Graphic>
                          <Label>{module.moduleName}</Label>
                        </Item>
                      {/each}
                    </List>
                  </ContentRow>
                {/if}
              {/if}

              <ContentRow>
                <Select
                  inputId="class-field"
                  class="gigxr-input {session.gigXrBranded ? 'hide-class-field' : ''}"
                  bind:value={session.classId}
                  variant="filled"
                  label="Class"
                  enhanced
                  disabled={!canEditSession}
                >
                  <Option value="" />
                  {#each classes as clazz (clazz.classId)}
                    <Option value={clazz.classId}>{clazz.className}</Option>
                  {/each}
                </Select>
              </ContentRow>
            </div>
          </div>

          <ContentRow>
            <FilledTextArea
              id="session-description-field"
              bind:value={session.description}
              label="Description"
              ariaLabel="session-description"
              disabled={!canEditSession}
            />
          </ContentRow>
        </div>

        <div slot="left" class="session-content-tab" hidden={activeTab !== 'Content'}>
          <!-- <SessionLabels bind:hmdJson={session.hmdJson}/> -->
          {#if isLegacyHoloPatient(session.clientAppId)}
            <SessionScenesPanel
              clientAppId={session.clientAppId}
              bind:hmdJson={session.hmdJson}
              sessionCreatedById={session.createdById}
            />
          {:else if isLegacyHoloHuman(session.clientAppId)}
            <PlatformStagePanel bind:hmdJson={session.hmdJson} sessionCreatedById={session.createdById} />
          {:else if !(isStudent && session.clientApp.clientAppName === 'HoloScenarios')}
            <PlatformV3ContentPanel
              inputLabel={isLegacyHoloChem(session.clientAppId) ? 'Experiment' : 'Scenario'}
              bind:hmdJson={session.hmdJson}
            />
          {/if}

          {#if !$mobileView}
            <div class="credits-link">
              <a href="https://www.gigxr.com/credits" target="_blank" rel="noopener noreferrer">CREDITS</a>
            </div>
          {/if}
        </div>

        <div slot="right">
          <SessionPermissionSelect bind:session disabled={!canEditSession} />

          <DateAccountComponent label="Created" utcDateString={session.createdOn} account={session.createdBy} />

          <DateAccountComponent label="Last Saved" utcDateString={session.modifiedOn} account={session.modifiedBy} />

          {#if $mobileView}
            <div class="credits-link">
              <a href="https://www.gigxr.com/credits" target="_blank" rel="noopener noreferrer">CREDITS</a>
            </div>
          {/if}

          {#if canEditSession}
            <ContentRowCenteredOnMobile>
              <Button id="edit-session-plan-button" class="gigxr-button" variant="unelevated" on:click={saveHandler}>
                <Label>Save Changes</Label>
              </Button>
            </ContentRowCenteredOnMobile>

            <ContentRowCenteredOnMobile>
              <!-- svelte-ignore a11y-missing-attribute -->
              <a
                id="session-discard-changes-link"
                class="gigxr-link"
                on:click={() => discardChangesDialog.open()}
              >Discard Changes</a>
            </ContentRowCenteredOnMobile>
          {/if}

          <ContentRowCenteredOnMobile>
            <!-- svelte-ignore a11y-missing-attribute -->
            {#if !shouldHideSaveAsSessionPlan}
              <a id="session-save-as-session-plan-link" class="gigxr-link" on:click={saveAsNewSessionPlanHandler}>Save
                as New Session Plan</a>
            {/if}
          </ContentRowCenteredOnMobile>

          {#if canEditSession}
            <ContentRowCenteredOnMobile>
              {#if session.sessionStatus === 'Archived'}
                <!-- svelte-ignore a11y-missing-attribute -->
                <a id="session-unarchive-link" class="gigxr-link" on:click={unarchiveSessionPlanHandler}>Unarchive</a>
              {:else}
                <!-- svelte-ignore a11y-missing-attribute -->
                <a id="session-archive-link" class="gigxr-link" on:click={() => archiveDialog.open()}>Archive</a>
              {/if}
            </ContentRowCenteredOnMobile>
          {/if}

          {#if sessionId}
            <ContentRowCenteredOnMobile>
              <a
                id="create-session-from-session-plan-link"
                class="gigxr-link"
                on:click={createSessionFromSessionPlan}
              >Create New Session from Plan</a>
            </ContentRowCenteredOnMobile>
          {/if}
        </div>
      </TwoColumnSection>
    </PrimaryContent>
  {/if}
</SecondaryBackgroundWrapper>

<GigXrDialog bind:dialog={archiveDialog} ariaPrefix="archive-session-plan">
  <GigXrDialogContent>
    <GigXrDialogIcon />
    Are you sure you want to archive this session plan?
  </GigXrDialogContent>
  <GigXrDialogActions>
    <GigXrDialogCancelButton>Cancel</GigXrDialogCancelButton>
    <GigXrDialogButton on:click={archiveSessionPlanHandler}>Archive</GigXrDialogButton>
  </GigXrDialogActions>
</GigXrDialog>

<DiscardChangesDialog bind:dialog={discardChangesDialog} clickHandler={() => (session = { ...lastSavedSession })} />

<div id="exportJsonElement" />

<style>
  .logo-row__logo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 1.5em;
  }

  .gigxr-version-number {
    margin: 10px auto;
    text-align: center;
  }

  .gigxr-branded-session-plan {
    background-image: url('/assets/gigxr-banner.png');
    background-repeat: no-repeat;
    background-size: 80px;
    width: 80px;
    height: 80px;
    position: absolute;
    top: -30px;
    left: 1em;
    z-index: 1;
  }

  :global(.hide-class-field) {
    visibility: hidden;
  }

  .credits-link {
    margin-top: 1rem;
    margin-bottom: 1rem;
    color: var(--gigxr-theme-primary-1c);
    text-decoration: underline;
  }

  @media (min-width: 1100px) {
    .logo-row {
      display: flex;
      justify-content: space-between;
    }

    .logo-row__logo {
      flex: 1;
      justify-content: start;
      margin-right: 1em;
    }

    .logo-row__content {
      flex: 3;
    }

    .gigxr-branded-session-plan {
      left: 105px;
    }
  }
</style>
