import Cookies from 'js-cookie';
import { Logger } from '../util/logs';
import { getJwt } from './json-web-tokens';
import { uuidEqual } from './util';

/**
 * Messaging to the Unity WebView when ran from a mobile app.
 */

/**
 * The URL scheme that the Unity WebView will be listening on.
 */
const UNITY_URL_SCHEME = 'uniwebview';

/**
 * The max safe message size that can be sent to the Unity WebView.
 *
 * @see https://docs.uniwebview.com/guide/messaging-system.html
 */
const UNITY_MAX_MESSAGE_SIZE_IN_BYTES = 16384;

/**
 * Unity message action types.
 *
 * These are used to either communicate from GMS to Unity, or from Unity to GMS.
 *
 * @enum {string}
 */
const UnityAction = {
  //
  // GMS => Unity
  //
  JSON_WEB_TOKEN: 'json-web-token',
  CLOSE_WEBVIEW: 'close-webview',
  EXIT: 'exit',
  JOIN_SESSION: 'join-session',
  JOIN_DEMO_SESSION: 'join-demo-session',
  CREATE_SESSION: 'create-session',

  // HoloPatient 2.0
  GET_CLIPS: 'get-clips',
  DOWNLOAD_CLIPS: 'download-clips',
  DOWNLOAD_CLIP_SET: 'download-clip-set',
  DELETE_CLIP: 'delete-clip',
  STOP_CLIP_DOWNLOAD: 'stop-clip-download',

  // Platform
  GET_DOWNLOADS: 'get-downloads',
  QUEUE_DOWNLOADS: 'queue-downloads',
  QUEUE_DOWNLOAD_SET: 'queue-download-set',
  DELETE_DOWNLOAD: 'delete-download',
  STOP_DOWNLOAD: 'stop-download',

  GET_CONTENT_PREFERENCES: 'get-content-preferences',
  SET_CONTENT_PREFERENCES: 'set-content-preferences',
  ENABLE_NOTIFICATIONS: 'enable-notifications',
  FIRST_TIME_EXPERIENCE_FINISHED: 'first-time-experience-finished',
  LOGOUT: 'logout',
  MINIMUM_LOG_LEVEL: 'minimum-log-level',
  GET_ENVIRONMENT_CREDENTIALS: 'get-environment-credentials',
  SWITCH_ENVIRONMENT: 'switch-environment',

  //
  // Unity => GMS
  //
  CONTENT_PREFERENCES_RESPONSE: 'content-preferences-response',
  NOTIFICATION_DIALOG_DISMISSED: 'notification-dialog-dismissed',

  // HoloPatient 2.0
  ASSETS_DOWNLOADED: 'assets-downloaded',
  DOWNLOAD_CLIP_SET_INSUFFICIENT_STORAGE: 'download-clip-set-insufficient-storage',
  DOWNLOAD_CLIP_SET_STORAGE_LIMITED_BY_PREFERENCE: 'download-clip-set-storage-limited-by-preference',
  CANNOT_EVICT_NEEDED_CLIPS: 'cannot-evict-needed-clips',

  // Platform
  GET_DOWNLOADS_RESPONSE: 'get-downloads-response',
  DOWNLOAD_SET_FAILED_INSUFFICIENT_STORAGE: 'download-set-failed-insufficient-storage',
  CANNOT_EVICT_NEEDED_RESOURCES: 'cannot-evict-needed-resources',

  ENVIRONMENT_CREDENTIALS: 'environment-credentials',
  LOG: 'log',
  SNACKBAR_MESSAGE: 'snackbar-message',
};
window.UnityAction = UnityAction;

const HOLOPATIENT_CLIENTAPPID = '0d9a8cc4-413f-4876-8e90-c1ad44b822e6';
const HOLOHUMAN_CLIENTAPPID = '0debb0e0-76fa-483e-9594-68c6be0558cf';
const HOLOCHEM_CLIENTAPPID = 'cf9c4260-83c2-4321-8df3-514f13ea5a27';
const HOLOSCENARIOS_CLIENTAPPID = '5516eb34-611a-4eac-b2d6-834a8c1d4509';

export const CONVERSATIONS_CLIENTAPPID = 'c24ca108-edfb-4287-b1c2-6cfa4cad2230';

function isLegacyHoloPatient(clientAppId) {
  return uuidEqual(clientAppId, HOLOPATIENT_CLIENTAPPID);
}

function isLegacyHoloHuman(clientAppId) {
  return uuidEqual(clientAppId, HOLOHUMAN_CLIENTAPPID);
}

function isLegacyHoloChem(clientAppId) {
  return uuidEqual(clientAppId, HOLOCHEM_CLIENTAPPID);
}

function isHoloScenarios(clientAppId) {
  return uuidEqual(clientAppId, HOLOSCENARIOS_CLIENTAPPID);
}

/**
 * Returns whether the container is a Unity environment.
 */
function isUnity() {
  return Cookies.get('gigxr-unity');
}

/**
 * Returns whether developer-specific features should be enabled.
 */
function isDeveloper() {
  return Cookies.get('gigxr-developer');
}

/**
 * Returns whether platform-specific features should be enabled.
 * @deprecated Platform features are now determined by the client app.
 */
function isPlatform() {
  return Cookies.get('gigxr-platform');
}

/**
 * Returns which app the GMS is embeded in, if applicable.
 */
function getClientAppId() {
  const clientAppId = Cookies.get('gigxr-clientAppId');

  // TODO: Remove when HoloPatient 2.0 is no longer needed.
  if (!clientAppId) {
    // Legacy HP 2.0 does not set clientAppId in the web view. Therefore, if no clientAppId is set then assume HP 2.0
    return HOLOPATIENT_CLIENTAPPID;
  }

  return clientAppId;
}

/**
 * Subscribes to a specific UnityAction.
 *
 * When unity sends a message for the provided action, the provided callback will be called.
 *
 * @param {UnityAction} action
 * @param {Function} callback
 */
function subscribeToUnityMessage(action, callback) {
  validateUnityAction(action);

  function listener(event) {
    callback(event.detail);
  }

  window.addEventListener(action, listener);

  return function unsubscribe() {
    window.removeEventListener(action, listener);
  };
}

/**
 * Send a message to the Unity WebView container.
 *
 * @param {UnityAction} action
 * @param {Object} payload A record of `USVString` keys and `USVString` values.
 */
function sendUnityMessage(action, payload = {}) {
  if (!isUnity()) {
    return;
  }

  validateUnityAction(action);

  // typeof null === 'object'; // true
  // Old ECMAScript bug that will never be fixed:
  // https://web.archive.org/web/20160331031419/http://wiki.ecmascript.org:80/doku.php?id=harmony:typeof_null
  if (typeof payload !== 'object' || payload === null) {
    throw new TypeError('payload is not an Object!');
  }

  // Build Unity message.
  const encodedPayload = encodeURIComponent(JSON.stringify(payload));
  const urlCommand = `${UNITY_URL_SCHEME}://${action}?payload=${encodedPayload}`;

  const byteSize = stringByteSize(urlCommand);
  if (byteSize > UNITY_MAX_MESSAGE_SIZE_IN_BYTES) {
    console.warn('Unity max message size exceeded!');
  }

  Logger.log(`Sending unity message: ${action}`);

  // Send Unity message.
  const inlineFrame = createInvisibleInlineFrame(urlCommand);
  document.body.appendChild(inlineFrame);

  // Clean up.
  document.body.removeChild(inlineFrame);
}

/**
 * Sends a message to Unity with a JSON Web Token.
 */
function sendUnityJsonWebTokenMessage() {
  sendUnityMessage(UnityAction.JSON_WEB_TOKEN, {
    jsonWebToken: getJwt(),
  });
}

/**
 * Sends a message to Unity to get a list of downloaded and downloading clips.
 */
function sendUnityGetClipsMessage() {
  sendUnityMessage(UnityAction.GET_CLIPS);
}

/**
 * Sends a message to Unity to download clips for the provided session.
 *
 * @param {Session} session
 * @param {boolean} canEvictNeededClips
 */
function sendUnityDownloadClipsForSessionMessage(session, canEvictNeededClips = false) {
  const clips = getClipsFromSession(session);
  sendUnityMessage(UnityAction.DOWNLOAD_CLIP_SET, {
    clips,
    canEvictNeededClips,
  });
}

/**
 * Sends a message to Unity to download the provided clips.
 *
 * @param {string[]} clips
 */
function sendUnityDownloadClipsMessage(clips) {
  sendUnityMessage(UnityAction.DOWNLOAD_CLIPS, {
    clips,
  });
}

/**
 * Sends a message to Unity to join the provided session.
 *
 * @param {Session} session
 */
function sendUnityJoinSessionMessage(session) {
  // const clips = getClipsFromSession(session);
  sendUnityMessage(UnityAction.JOIN_SESSION, {
    sessionId: session.sessionId,
  });
}

/**
 * Sends a message to Unity to join the provided session in single user mode.
 *
 * @param {Session} session
 */
function sendUnityJoinDemoSessionMessage(session) {
  sendUnityMessage(UnityAction.JOIN_DEMO_SESSION, {
    sessionId: session.sessionId,
  });
}

/**
 * Sends a message to Unity to create the provided session.
 *
 * @param {Session} session
 */
function sendUnityCreateSessionMessage(session) {
  sendUnityMessage(UnityAction.CREATE_SESSION, {
    sessionId: session.sessionId,
  });
}

/**
 * Sends a message to Unity to enable notifications.
 */
function sendUnityEnableNotificationsMessage() {
  sendUnityMessage(UnityAction.ENABLE_NOTIFICATIONS);
}

/**
 * Sends a message to Unity to delete a clip.
 */
function sendUnityDeleteClipMessage(clip) {
  sendUnityMessage(UnityAction.DELETE_CLIP, {
    clip,
  });
}

/**
 * Sends a message to Unity to cancel an in progress clip download.
 */
function sendUnityStopClipDownloadMessage(clip) {
  sendUnityMessage(UnityAction.STOP_CLIP_DOWNLOAD, {
    clip,
  });
}

/**
 * Sends a message to Unity to get a list of downloaded and downloading resources.
 */
function sendUnityGetDownloadsMessage() {
  sendUnityMessage(UnityAction.GET_DOWNLOADS);
}

/**
 * Sends a message to Unity to download resources for the provided session.
 *
 * @param {Session} session
 * @param {boolean} canEvictNeededResources
 */
function sendUnityDownloadResourcesForSessionMessage(session, canEvictNeededResources = false) {
  const resourceIds = getResourceIdsForSession(session);
  sendUnityMessage(UnityAction.QUEUE_DOWNLOAD_SET, {
    resourceIds,
    canEvictNeededResources,
  });
}

/**
 * Sends a message to Unity to download the provided resources.
 *
 * @param {string[]} resourceIds
 */
function sendUnityDownloadResourcesMessage(resourceIds) {
  sendUnityMessage(UnityAction.QUEUE_DOWNLOADS, {
    resourceIds,
  });
}

/**
 * Sends a message to Unity to delete a download.
 */
function sendUnityDeleteDownloadMessage(resourceId) {
  sendUnityMessage(UnityAction.DELETE_DOWNLOAD, {
    resourceId,
  });
}

/**
 * Sends a message to Unity to cancel an in progress download.
 */
function sendUnityStopDownloadMessage(resourceId) {
  sendUnityMessage(UnityAction.STOP_DOWNLOAD, {
    resourceId,
  });
}

/**
 * Sends a message to Unity to get the content preferences for this device.
 */
function sendUnityGetContentPreferencesMessage() {
  sendUnityMessage(UnityAction.GET_CONTENT_PREFERENCES);
}

/**
 * Sends a message to Unity to set the content preferences for this device.
 */
function sendUnitySetContentPreferencesMessage({ allowAutomaticBackgroundDownloads, maximumContentStorage }) {
  sendUnityMessage(UnityAction.SET_CONTENT_PREFERENCES, {
    allowAutomaticBackgroundDownloads,
    maximumContentStorage,
  });
}

/**
 * Sends a message to Unity to let it know the first time experience is finished.
 */
function sendUnityFirstTimeExperienceFinishedMessage() {
  sendUnityMessage(UnityAction.FIRST_TIME_EXPERIENCE_FINISHED);
}

/**
 * Sends a message to Unity to let it know the user logged out and to clear any stored credentials.
 */
function sendUnityLogoutMessage() {
  sendUnityMessage(UnityAction.LOGOUT);
}

/**
 * Sends a message to Unity to set the minimum log level to be sent to cloud services.
 */
function sendUnityMinimumLogLevel(minimumLogLevel) {
  sendUnityMessage(UnityAction.MINIMUM_LOG_LEVEL, {
    minimumLogLevel,
  });
}

/**
 * Sends a message to Unity to get the credentials to switch environments.
 */
function sendUnityGetEnvironmentCredentialsMessage() {
  sendUnityMessage(UnityAction.GET_ENVIRONMENT_CREDENTIALS);
}

/**
 * Sends a message to Unity to switch the environment (staging, prod, etc.)
 */
function sendUnitySwitchEnvironmentMessage(environment) {
  sendUnityMessage(UnityAction.SWITCH_ENVIRONMENT, {
    environment,
  });
}

/**
 * The main function that Unity will call to send messages to the GMS.
 *
 * @param {UnityAction} action
 * @param {Object} payload
 */
window.receiveUnityMessage = function (action, payload = {}) {
  validateUnityAction(action);
  const event = new CustomEvent(action, { detail: payload });
  window.dispatchEvent(event);
};

/**
 * Creates an <iframe> element with the provided `src` with no width and height;
 *
 * @param {string} src
 */
function createInvisibleInlineFrame(src) {
  const element = document.createElement('iframe');
  element.setAttribute('src', src);
  element.style.width = '0';
  element.style.height = '0';
  return element;
}

/**
 * Returns an array of clips needed for the provided session.
 *
 * @param {*} session
 * @returns {[string]} clips The clips needed for the provided session.
 */
function getClipsFromSession(session) {
  if (!session || !session.hmdJson || !session.hmdJson.scenes) {
    return [];
  }

  const usedClips = new Set();

  return session.hmdJson.scenes
    .filter((scene) => {
      if (usedClips.has(scene.title)) {
        return false;
      }

      usedClips.add(scene.title);
      return true;
    })
    .map((scene) => scene.title);
}

/**
 * Returns an array of resourceIds needed for the provided session.
 *
 * @param {*} session
 * @returns {[string]} The resourceIds needed for the provided session.
 */
export function getResourceIdsForSession(session) {
  if (!session || !session.hmdJson || !session.hmdJson.resources) {
    return [];
  }

  return session.hmdJson.resources.map((resource) => resource.resourceId);
}

/**
 * Validates whether the provided action is a valid UnityAction.
 *
 * Throws a TypeError if it is not.
 *
 * @param {UnityAction} action
 */
function validateUnityAction(action) {
  if (!Object.values(UnityAction).includes(action)) {
    throw new TypeError(`${action} is not a valid UnityAction!`);
  }
}

/**
 * Returns the size of a string in bytes.
 * @see https://dev.to/rajnishkatharotiya/get-byte-size-of-the-string-in-javascript-20jm
 * @param {string} value
 */
function stringByteSize(value) {
  return new Blob([value]).size;
}

export {
  UnityAction,
  isLegacyHoloPatient,
  isLegacyHoloHuman,
  isLegacyHoloChem,
  isHoloScenarios,
  isUnity,
  isPlatform,
  isDeveloper,
  getClientAppId,
  subscribeToUnityMessage,
  sendUnityMessage,
  sendUnityJsonWebTokenMessage,
  sendUnityGetClipsMessage,
  sendUnityDownloadClipsForSessionMessage,
  sendUnityDownloadClipsMessage,
  sendUnityJoinSessionMessage,
  sendUnityJoinDemoSessionMessage,
  sendUnityCreateSessionMessage,
  sendUnityDeleteClipMessage,
  sendUnityStopClipDownloadMessage,
  sendUnityGetDownloadsMessage,
  sendUnityDownloadResourcesForSessionMessage,
  sendUnityDownloadResourcesMessage,
  sendUnityDeleteDownloadMessage,
  sendUnityStopDownloadMessage,
  sendUnityGetContentPreferencesMessage,
  sendUnitySetContentPreferencesMessage,
  sendUnityEnableNotificationsMessage,
  sendUnityFirstTimeExperienceFinishedMessage,
  sendUnityLogoutMessage,
  sendUnityMinimumLogLevel,
  sendUnityGetEnvironmentCredentialsMessage,
  sendUnitySwitchEnvironmentMessage,
  getClipsFromSession,
};
